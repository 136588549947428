<template>
    <v-form ref="pets">
        <form-section :sectionTitle="$t('applications.pets')" wideFirstCol>
            <template v-slot:col-1>
                <div v-for="pet in entitiesData" :key="pet.id">
                    <control-tool-bar
                        :id="pet.id"
                        :title="$t('applications.pet')"
                        @remove="removeEntity(pet.id)"
                        :removeable="pet.removeable"
                    ></control-tool-bar>
                    <applicant-pet
                        :modelData="pet"
                        @update="update(pet.id, 'root', $event)"
                    ></applicant-pet>
                </div>
                <v-layout class="mt-1" v-if="entitiesData.length < MAX_PETS">
                    <v-btn
                        color="secondary"
                        class="mb-2"
                        dark
                        @click="createEntity('pet')"
                    >
                        <v-icon left>add_circle</v-icon
                        >{{ $t("applications.add-pet") }}
                    </v-btn>
                </v-layout>
            </template>
        </form-section>
    </v-form>
</template>

<script>
import InteractsWithEntities from "@components/application/mixins/InteractsWithEntities";

export default {
    name: "applicant-pets",
    components: {
        ApplicantPet: () => import("./ApplicantPet"),
        ControlToolBar: () => import("./ControlToolBar"),
    },
    mixins: [InteractsWithEntities],
    props: {},
    computed: {},
    data() {
        return {
            MAX_PETS: 4,
            entityIdKey: "id",
            name: this.$t("applications.pets"),
        };
    },
    watch: {},
    methods: {
        validate() {
            return this.$refs["pets"].validate();
        },
    },
};
</script>

<style scoped>
</style>